import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import $ from 'jquery'
import axios from "axios";
import JsonExcel from 'vue-json-excel'
import vSelect from 'vue-select'
import moment from 'moment';
import DualListBox from "dual-listbox-vue";

Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)


export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin,
        DualListBox
    },
    data: function() {
        return {
            pageTitle: "Liste des approvisionnements",

            vuetable: {
                link: this.BASE_URL + "/approreglement/fetchappro",
                moreParams: {},
                fields: [
                    // {
                    //     name: VuetableFieldCheckbox,
                    //     titleClass: 'text-center',
                    //     dataClass: 'text-center',
                    //     selectable
                    // },
                    {
                        name: 'date_appro_formatted',
                        title: 'Date',
                        sortField: 'date_appro',
                        dataClass: 'text-center',
                    },
                    {
                        name: 'nom_magasin',
                        title: 'Magasin',
                        dataClass: 'text-left',
                        sortField: 'nom_magasin',
                    },
                    {
                        name: 'num_bon_livraison',
                        title: 'Numero BL',
                        dataClass: 'text-center',
                        sortField: 'num_bon_livraison',
                    },
                    {
                        name: 'nom_fournisseur',
                        title: 'Fournisseur',
                        dataClass: 'text-left',
                        sortField: 'nom_fournisseur',
                    },
                    {
                        name: 'total_a_payer',
                        title: 'Total à payer',
                        dataClass: 'text-right text-bold text-success',
                        sortField: 'total_a_payer',
                        width: "150px",
                        // formatter(value) {
                        //     return new Intl.NumberFormat().format(value)
                        // }
                    },
                    {
                        name: 'total_restant',
                        title: 'Reste à payer',
                        dataClass: 'text-right text-bold text-danger',
                        sortField: 'total_restant',
                        width: "150px",
                        // formatter(value) {
                        //     return new Intl.NumberFormat().format(value)
                        // }
                    },
                    {
                        name: 'statut_paiement',
                        title: 'Statut',
                        dataClass: 'text-left text-center',
                        titleClass: "text-center",
                        sortField: 'statut_paiement',
                        width: "100px"
                    },
                    {
                        name: 'date_heure_enregistrement',
                        title: 'Date enregistrement',
                        dataClass: 'text-left',
                        titleClass: 'text-left',
                        sortField: 'date_heure_enregistrement',
                        width: "150px"
                    },

                    // {
                    //     name: 'actions',
                    //     width: "110px",
                    //     dataClass: "text-center",
                    //     titleClass: 'text-center',
                    // }

                ],
                sortOrder: [
                    { field: 'date_appro', direction: 'asc' }
                ],
                css: {
                    table: {
                        tableClass: 'table table-striped table-bordered table-hovered',
                        loadingClass: 'loading',
                        ascendingIcon: 'fas fa-arrow-up fa-sm',
                        descendingIcon: 'fas fa-arrow-down fa-sm',
                        handleIcon: 'fas fa-bars fa-sm',
                    },
                    pagination: {
                        infoClass: 'pull-left ',
                        wrapperClass: 'vuetable-pagination text-center',
                        activeClass: 'btn-secondary',
                        disabledClass: 'disabled',
                        pageClass: 'btn btn-border',
                        linkClass: 'btn btn-border',
                        icons: {
                            first: '',
                            prev: '',
                            next: '',
                            last: '',
                        },
                    }
                },
            },
            exportexcel: {
                json_fields: {
                    'Date': 'date_reglement_formatted',
                    'Numero BL': 'num_bon_livraison',
                    'Fournisseur': 'nom_fournisseur',
                    'Total Montant HT': 'total_ht',
                    'Total Montant TVA': 'total_tva',
                    'Total Montant TTC': 'total_ttc',
                    'Total Remise': 'total_remise',
                    'Total Montant A payer': 'total_a_payer',
                    'Commentaire': 'commentaire',
                    'Date Heure Enregistrement': 'date_heure_enregistrement'
                },
                json_data: [],
                json_meta: [
                    [{
                        'key': 'charset',
                        'value': 'utf-8'
                    }]
                ],
            },
            datepicker: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
            },
            motCle: "",
            crudform: {
                id: "",
                date_reglement_formatted: moment().format('DD/MM/YYYY'),
                date_echeance: moment().format('DD/MM/YYYY'),
                appro_id: "",
                montant: "",
                reference: "",
                mode_paiement: "ESPECE",
                piece_jointe: "",
                remarques: "",
                reste_a_payer: "",
            },
            listdata: {
                reglements: []
            },
            criterearticle: {
                famille: "",
                search: ""
            },
            fournisseur: {},
            appro: {},
            selectedRow: {
                data: {},
                index: 0
            },
            numero_bl: "",
            firstload: true,
            has_echeance: false,
            somme_compte_client: {
                total_a_payer: '',
                total_deja_paye: '',
                total_restant: '',
            },
            paiementType: 'auto',
            montant_total_reste_a_payer:""

        }
    },
    methods: {
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },
        deleteRow(id) {
            //alert("You clicked delete on" + JSON.stringify(rowData));
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment supprimer cette ligne?', {
                    title: 'Confirmation',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Supprimer',
                    cancelTitle: 'Annuler',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        axios.post(that.BASE_URL + "/approreglement/delete/" + id).then(function(response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log(response.data.message);
                                that.setFilter();
                                that.fetchreglements(that.selectedRow.data.id);
                                Vue.$toast.open({
                                    message: response.data.message,
                                    type: 'success',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                })

        },
        onLoading() {
            console.log('loading... show your spinner here')
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');

            if (this.firstload && this.$refs.vuetable.$data.tableData.length > 0) {
                this.selectRow(this.$refs.vuetable.$data.tableData[0], 0);
            }
            this.firstload = false;
        },
        setFilter() {
            this.vuetable.moreParams.filter = this.motCle;
            this.vuetable.moreParams.criteriacol = this.criteriacol;
            if (this.$route.name == 'Approreglementparfournisseur') {
                this.vuetable.moreParams.fournisseurs_id = this.$route.params.id;
            }
            if (this.$route.name == 'Approreglementparappro') {
                this.vuetable.moreParams.id = this.$route.params.id;
            }

            Vue.nextTick(() => this.$refs.vuetable.refresh());
            
            var that = this;
            
            axios.post(that.BASE_URL + "/fournisseurs/fetchmontanttotal", this.vuetable.moreParams).then(function(response) {
                that.somme_compte_client = response.data[0];
                // console.log(that.somme_compte_client);
            });
        },
        resetFilter() {
            this.motCle = "";
            this.criteriacol = "";
            this.vuetable.moreParams.filter = this.motCle;
            if (this.$route.name == 'Approreglementparfournisseur') {
                this.vuetable.moreParams.fournisseurs_id = this.$route.params.id;
            }
            if (this.$route.name == 'Approreglementparappro') {
                this.vuetable.moreParams.id = this.$route.params.id;
            }
            //this.vuetable.moreParams.fournisseurs_id = 4;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        onExport() {

            this.exportexcel.json_data = this.$refs.vuetable.$data.tableData;
            $("#excel-download").trigger("click");
        },
        openModal() {
            this.$bvModal.show("myCustomModal");
        },
        closeModal() {
            this.$bvModal.hide("myCustomModal");
        },
        onSubmit() {
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                    title: 'Confirmation',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'Continuer',
                    cancelTitle: 'Annuler',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = that.BASE_URL + "/approreglement/addaction";
                        axios.post(link, $("#formulaire").serialize()).then(function(response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                that.initForm();
                                that.setFilter();
                                that.fetchreglements(that.selectedRow.data.id);
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },
        fetchreglements: function(id_appro) {
            this.listdata.reglements = [];
            axios.get(this.BASE_URL + "/approreglement/fetchbyappro/" + id_appro).then(response => {
                this.listdata.reglements = response.data;
            });
        },
        getFournisseur: function(id_fournisseur) {
            if (id_fournisseur == '' || id_fournisseur == null) {
                this.fournisseur = {};
            } else {
                axios.get(this.BASE_URL + "/fournisseurs/get/" + id_fournisseur).then(response => {
                    this.fournisseur = response.data[0];
                    this.pageTitle = "Liste des approvisionnements du fournisseur : " + this.fournisseur.nom_fournisseur;
                    this.$emit('change-page', this.pageTitle);
                });
            }

        },
        getApprovisionnement: function(id_appro) {
            console.log("FAC: " + id_appro);
            if (id_appro == '' || id_appro == null) {
                this.appro = {};
            } else {
                axios.get(this.BASE_URL + "/approvisionnement/get/" + id_appro).then(response => {
                    this.appro = response.data[0];
                    this.pageTitle = "Historique des règlements de l'appro du BL N° " + this.appro.num_bon_livraison;
                    this.$emit('change-page', this.pageTitle);
                    console.log(this.pageTitle);
                });
            }

        },
        updateMontant: function() {
            // this.crudform.reste_a_payer = Number(this.selectedRow.data.total_restant) - Number(this.crudform.montant);
            this.crudform.reste_a_payer = Number(this.montant_total_reste_a_payer) - Number(this.crudform.montant);
        },
        initForm: function() {
            this.crudform.date_reglement_formatted = moment().format('DD/MM/YYYY');
            this.crudform.mode_paiement = "ESPECE";
            this.crudform.reference = "";
            this.crudform.remarques = "";
            this.crudform.montant = 0;
        },
        selectRow: function(rowData, index) {
            this.selectedRow.index = index;
            this.selectedRow.data = rowData;
            this.crudform.appro_id = rowData.id;
            // this.crudform.reste_a_payer = rowData.total_restant;
            
            if(this.paiementType == 'auto') {
                this.crudform.reste_a_payer = this.montant_total_reste_a_payer;
                this.montant_total_reste_a_payer = Number(this.somme_compte_client.total_restant);
            }
            else {
                this.crudform.reste_a_payer = rowData.total_restant;
                this.montant_total_reste_a_payer = Number(rowData.total_restant);
            }


            this.initForm();
            this.fetchreglements(rowData.id);
        },
        onRowClicked: function(e) {
            this.selectRow(e.data, e.index);

        },
        onRowClass: function(dataItem) {
            if (dataItem.id == this.selectedRow.data.id)
                return 'selected-row';
            return '';
        }

    },
    computed: {
        articlesList: function() {
            var that = this;
            return this.listdata.articles.filter(function(article) {
                if (article.ref_article.toLowerCase().includes(that.criterearticle.search.toLowerCase()) || article.designation.toLowerCase().includes(that.criterearticle.search.toLowerCase())) {
                    return true;
                }
                return false;
            });
        }
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);

        this.motCle = "";
        this.criteriacol = "";

        this.resetFilter();

        if (this.$route.name == 'Approreglementparfournisseur') {
            this.getFournisseur(this.$route.params.id);
            this.setFilter();
        }
        if (this.$route.name == 'Approreglementparappro') {
            this.getApprovisionnement(this.$route.params.id);
        }

    },
    watch: {
        'selectedRow.data': function() {
            console.log(this.selectedRow.data)
            if (this.selectedRow.data != null && this.selectedRow.data != undefined && this.selectedRow.data != {}) {
                this.numero_bl = this.selectedRow.data.num_bon_livraison;
            } else {
                this.numero_bl = "";
            }
        },
        paiementType: function() {
            this.initForm();
            if(this.paiementType == 'auto') {
                this.crudform.reste_a_payer = this.montant_total_reste_a_payer;
                this.montant_total_reste_a_payer = Number(this.somme_compte_client.total_restant);
            }
            else {
                this.crudform.reste_a_payer = this.selectedRow.data.total_restant;
                this.montant_total_reste_a_payer = this.selectedRow.data.total_restant;
                // console.log(this.crudform);
            }
        }


    },
    mounted: function() {

    }
}